import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        {/* <!-- Hero Section --> */}
        <div className="container">
          <div className="col-12">
            <img
              className="w-100"
              src={"/img/404-page.png"}
              alt={"404 - Lost in space"}
              loading="lazy"
            />
          </div>
        </div>
        {/* <!-- End Hero Section --> */}

        <div className="w-100">
          <div className="overflow-hidden mt-n2">
            <figure className="right-0 bottom-0 left-0 mb-n1 bg-background-white">
              <svg
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1920 90"
              >
                <path
                  fill="#171D3F"
                  d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                />
              </svg>
            </figure>
          </div>
        </div>
        {/* <!-- End Bottom Section --> */}
      </Layout>
    )
  }
}

export default NotFoundPage
